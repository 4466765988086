import React, {forwardRef} from "react";
import styles from './Projects.module.scss'

const Projects:React.ForwardRefRenderFunction<HTMLElement> = (props,ref) => {
  return (
    <section className={styles.wrapper} ref={ref}>
      <div className={styles.content}>
        <span className={styles.sectionName}>Projects</span>
        <div className={styles.textWrapper}>
          <p>
            Our journey has been defined by 14 exceptional projects that have not only showcased our expertise but have also delivered tangible value to our esteemed clients. Ranging from large-scale enterprise solutions to intricate software developments, each project has been a testament to our commitment to excellence and innovation in the realm of IT solutions.
          </p>
        </div>
      </div>
    </section>
  )
}

export default forwardRef(Projects)
