import Vahan from "../../images/Vahan.jpg";
import Arsen from "../../images/Arsen.jpg";
import Artur from "../../images/Artur.jpg";
import Yegor from "../../images/Yegor.jpg";
import Gohar from "../../images/Gohar.jpg";
import Lucy from "../../images/Lucy.jpg";
import Narek from "../../images/Narek.jpg"

export interface Person {
  id: number;
  name: string;
  image: string;
  activity: string;
}
export const peoples: Person[] = [
  { id: 0, image: Yegor, name: "Yegor Karapetyan", activity: "Teach lead" },
  { id: 1, image: Artur, name: "Artur Sirunyan", activity: "Software engineer" },
  { id: 2, image: Vahan, name: "Vahan Simonyan", activity: "Software engineer" },
  { id: 3, image: Arsen, name: "Arsen Sirunyan", activity: "Software engineer" },
  { id: 4, image: Narek, name: "Narek Knyazyan", activity: "Software engineer" },
  { id: 5, image: Gohar, name: "Gohar Sirunyan", activity: "HR manager" },
  { id: 6, image: Lucy, name: "Lucy Nazaryan", activity: "UI/UX designer" },
];