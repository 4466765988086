import React, {forwardRef} from "react";
import styles from './AboutUs.module.scss';

const AboutUs: React.ForwardRefRenderFunction<HTMLElement> = (props, ref) => {

  return (
    <section className={styles.wrapper} ref={ref}>
      <span className={styles.sectionName}>About us</span>
      <div className={styles.content}>
        <div className={styles.textWrapper}>
          <h1>Welcome to Intellzy</h1>
          <div className={styles.mainText}>
            <p>
              Welcome to Intellzy, where innovation meets intelligence! We are a dynamic IT company committed to transforming businesses through cutting-edge technology solutions. Our name, Intellzy, reflects our core values of intelligence, innovation, and zeal.
            </p>
            <p>
              At Intellzy, we believe in the power of technology to inspire and drive meaningful change. Our journey began
              with a vision to simplify complexities and empower organizations to navigate the digital landscape seamlessly.
              With a client-centric approach and a focus on delivering tangible results, we have built strong and enduring
              partnerships with businesses across various sectors.
            </p>
            <p>
              Our core values of integrity, innovation, and customer satisfaction are deeply embedded in everything we do.
              We take pride in our ability to understand our clients' unique needs and provide tailored solutions that
              propel their growth and success. Whether it's optimizing operations, enhancing security, or implementing
              scalable software solutions, we are dedicated to helping businesses thrive in the digital era.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default forwardRef(AboutUs);