import React, { forwardRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styles from "./Team.module.scss";
import {peoples, Person} from "./TeamConstans";
import cn from "classnames";

gsap.registerPlugin(ScrollTrigger);



const Team:React.ForwardRefRenderFunction<HTMLElement> = ((props,ref) => {

  useEffect(() => {
    const fadeUpElements = gsap.utils.toArray('.fade-up') as HTMLDivElement[];
    fadeUpElements.forEach((box) => {
      const anim = gsap.fromTo(
        box,
        { autoAlpha: 0, y: 150 },
        { duration: 1, autoAlpha: 1, y: 0 }
      );
      ScrollTrigger.create({
        trigger: box,
        animation: anim,
        start: 'top 100%',
        end: 'top 80%',
        scrub: 1,
        toggleActions: 'play none none none',
      });
    });
  }, []);

  const renderWorker = (worker:Person) => {

    const workerClass = worker.id % 2 ? styles.rightWorker : styles.leftWorker;

    return (
      <div key={worker.id} className={cn(workerClass, "fade-up")}>
        <img src={worker.image} alt={worker.name} className={styles.image} />
        <div className={styles.personInfo}>
          <p className={styles.name}>{worker.name}</p>
          <p className={styles.activity}>{worker.activity}</p>
        </div>
      </div>
    );
  };

  return (
    <section className={styles.wrapper} ref={ref}>
      <span className={styles.sectionName}>Team</span>
      <div className={styles.content}>
        <div className={styles.workersWrapper}>
          {peoples.map((worker) => renderWorker(worker))}
        </div>
      </div>
    </section>
  );
});

export default forwardRef(Team);