import React, {forwardRef, useState} from "react";
import styles from './Services.module.scss'
import {Icons} from "../../components/Icons/Icons";
import cn from "classnames";



 interface Service {
  id: number;
  title: string;
  open: boolean;
  description: string;
}
const Services:React.ForwardRefRenderFunction<HTMLElement> = (props,ref) => {

  const [services,setServices] = useState<Service[]>([
    {
      id:0,
      title:"Web Development",
      open:false,
      description:"Our experienced consultants provide strategic guidance and in-depth analysis to help you make informed decisions, optimize your IT infrastructure, and achieve your long-term business goals."
    },
    {
      id:1,
      title:"Custom Software Development",
      open:false,
      description:"Utilize our expertise in custom software development to create solutions that align with your business objectives.",
    },
    {
      id:2,
      title:"IT Consulting",
      open:false,
      description:"Benefit from our strategic IT consulting services, guiding you through the ever-evolving technological landscape to make informed decisions.",
    }
  ])

  const arrowOpen = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, id: number) => {
    const updatedServices = services.map((service) => {
      if (service.id === id) {
        return { ...service, open: !service.open};
      }
      return { ...service, open: false};
    });
    setServices(updatedServices);
  };


  return (
    <section className={styles.wrapper} ref={ref}>
      <span className={styles.sectionName}>Services</span>
      <div className={styles.content}>
        <div className={styles.services}>
          {
            services.map((service) => {
              return (
                <React.Fragment key={service.id}>
                  <div className={styles.consulting} onClick={(event) => arrowOpen(event,service.id)}>
                    <div className={styles.head}>
                      <p>{service.title}</p>
                      <Icons name={service.open ? "arrowUp":"arrowDown"} className={styles.arrows}/>
                    </div>
                    <div className={cn(styles.description)}>
                      {
                        service.open &&  <p>
                          {service.description}
                        </p>
                      }
                    </div>
                  </div>
                  <div className={styles.line}/>
                </React.Fragment>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default forwardRef(Services)
