import React, {forwardRef, useEffect, useRef, useState} from 'react';
import styles from './ContactUs.module.scss';
import Footer from '../../components/Footer';
import emailjs from 'emailjs-com';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './ValidationSchema';
import Alert from "../../components/Alert"


const ContactUs: React.ForwardRefRenderFunction<HTMLElement> = (
  props,
  ref
) => {
  const form = useRef<HTMLFormElement>(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async () => {
    try {
      await emailjs.sendForm('service_g1nv29m', 'template_93eis4g', form.current!, 'oUiPdqkb8aV5bl4Rm');
      setShowSuccessAlert(true);
      reset();
    } catch (error) {
      setShowErrorAlert(true);
    }
  };


  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSuccessAlert(false)
      setShowErrorAlert(false)
    }, 4000);

    return () => clearTimeout(timeout);
  }, [showSuccessAlert, showErrorAlert]);


  const handleAlertClose = () => {
    setShowSuccessAlert(false)
    setShowErrorAlert(false)
  };


  return (
    <>
      <section className={styles.wrapper} ref={ref}>
        {
          showSuccessAlert && (
          <Alert
            message="Success"
            type="success"
            onClose={() => handleAlertClose()}
           />
        )}
        {
          showErrorAlert && (
          <Alert
            message="Error"
            type="error"
            onClose={() => handleAlertClose()}
          />
        )}
        <span className={styles.sectionName}>Contact Us</span>
        <div className={styles.content}>
          <form
            ref={form}
            onSubmit={handleSubmit(onSubmit)}
            className={styles.inputs}
          >
            <Controller
              control={control}
              name="from_name"
              render={({ field }) => (
                <div className={styles.inputWrapper}>
                  <input
                    type="text"
                    {...field}
                    value={field.value || ''}
                    className={styles.input}
                    placeholder="Full Name"
                  />
                  {errors.from_name && (
                    <p className={styles.error}>{errors.from_name.message}</p>
                  )}
                </div>
              )}
            />
            <Controller
              control={control}
              name="email_id"
              render={({ field }) => (
                <div className={styles.inputWrapper}>
                  <input
                    type="email"
                    {...field}
                    value={field.value || ''}
                    className={styles.input}
                    placeholder="Email"
                  />
                  {errors.email_id && (
                    <p className={styles.error}>{errors.email_id.message}</p>
                  )}
                </div>
              )}
            />
            <Controller
              control={control}
              name="message"
              render={({ field }) => (
                <div className={styles.inputWrapper}>
                  <textarea
                    {...field}
                    value={field.value || ''}
                    placeholder="Message"
                    className={styles.textarea}
                  />
                  {errors.message && (
                    <p className={styles.error}>{errors.message.message}</p>
                  )}
                </div>
              )}
            />
            <button type="submit" className={styles.submitButton}>
              Submit
            </button>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default forwardRef(ContactUs);
