import React from 'react';
import styles from "./Alert.module.scss";
import {Icons} from "../Icons/Icons";
import cn from "classnames";

interface AlertProps {
  message: string;
  type: string;
  onClose?: () => void;
}

const Alert: React.FC<AlertProps> = ({ message, type, onClose }) => {
  return (
      <div className={cn(styles.alert,styles[type])}>
        <span>
           {message}
        </span>
        <Icons name={"close"} className={styles.closeBtn} onClick={onClose}/>
      </div>
    )
};

export default Alert;