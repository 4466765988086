import React, {forwardRef, useEffect, useRef} from "react";
import styles from './Welcome.module.scss'

const Welcome:React.ForwardRefRenderFunction<HTMLElement> = (props,ref) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
//   const drawCircle = (canvas:HTMLCanvasElement,ctx: CanvasRenderingContext2D) => {
//     const circleX = canvas.width * 70 / 100;
//     const circleY = 0;
//     const radius = canvas.height / 2;
//
//     // Создаем градиент для заполнения круга
//     const gradient = ctx.createRadialGradient(circleX, circleY, 0, circleX, circleY, radius);
//     gradient.addColorStop(0, 'rgba(255,230,0,0.4)'); // Начальный цвет (полностью непрозрачный)
//     gradient.addColorStop(1, 'rgba(255,230,0,0)'); // Конечный цвет (полностью прозрачный)
//
//     // Заполняем круг градиентом
//     ctx.fillStyle = gradient;
//     ctx.arc(circleX, circleY, radius, 0, 2 * Math.PI);
//     ctx.fill();
//
//     ctx.beginPath();
//   }
//
//   const generateWave = (canvas:HTMLCanvasElement,ctx: CanvasRenderingContext2D,waveCount:number) => {
//     const waveX = 0
//     const waveY = canvas.height * 70 / 100
//     const eachWaveWidth = canvas.width / waveCount;
//
//     ctx.moveTo(waveX,waveY)
//
//     for (let i = 0; i < waveCount; i++){
//       const currentWaveStartX = eachWaveWidth * i
//       const currentWaveEndX = currentWaveStartX + eachWaveWidth
//       const currentWaveControlX = currentWaveStartX + eachWaveWidth / 2
//
//       const currentWaveControlY = i%2?waveY+200:waveY-200
//
//       ctx.bezierCurveTo(currentWaveStartX, waveY, currentWaveControlX, currentWaveControlY, currentWaveEndX, waveY)
//
//       // console.log("currentWaveStartPosition",currentWaveStartX)
//     }
//     ctx.lineTo(canvas.width, canvas.height);
//     ctx.lineTo(waveX, canvas.height);
//     ctx.lineTo(waveX, waveY);
//
//     // console.log(canvas.width)
//     // console.log(eachWaveWidth)
//   }
//
//   useEffect(() => {
//     const canvas = canvasRef?.current
//     const ctx = canvas?.getContext('2d')
//
//     if(ctx && canvas){
//       ctx.clearRect(0, 0, canvas.width, canvas.height);
//       const scaleFactor = window.devicePixelRatio;
//       canvas.width = canvas.offsetWidth * scaleFactor;
//       canvas.height = canvas.offsetHeight * scaleFactor;
//
// // Установите масштаб для отображения в изначальном размере
//       ctx.scale(scaleFactor, scaleFactor);
//
//       drawCircle(canvas,ctx)
//
//
//
//       ctx.beginPath();
//       generateWave(canvas,ctx,4)
//
//
//       // ctx.quadraticCurveTo(800, 900, 1200, 950);
//
//       // ctx.lineTo(waveX + canvas.width, waveY);
//
//       // ctx.lineTo(canvas.width, canvas.height);
//
//       // ctx.lineTo(waveX, canvas.height);
//       //
//       // ctx.lineTo(waveX, waveY);
//       //
//       // ctx.closePath();
//
//       ctx.fillStyle = '#FFE600'; // Заливка цветом
//
//       ctx.stroke()
//       ctx.fill();
//
//       //
//     }
//   },[])


  return (
    <section className={styles.wrapper} ref={ref}>
      <canvas className={styles.canvas} ref={canvasRef}/>
      <div className={styles.content}>
        <p className={styles.title}>Where Technology Meets Vision</p>
      </div>
    </section>
  )
}

export default forwardRef(Welcome)
