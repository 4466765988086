import React from 'react';
import styles from "./Footer.module.scss"
import {Icons} from "../Icons/Icons";
const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.contactInfo}>
        <div className={styles.email}>
         <strong>Email:</strong>contact@intellzy.am
        </div>
        <div className={styles.number}>
          <strong>Telephone:</strong>+374 93 554884
        </div>
      </div>
      <div className={styles.socialMedias}>
        <a href={"https://www.facebook.com/"}>
        <Icons name={"facebook"} className={styles.icons}/>
        </a>
         <a href={"https://www.instagram.com/intellzy_it/"} >
          <Icons name={"instagram"} className={styles.icons}/>
        </a>
        <a href={""}>
          <Icons name={"linkedIn"} className={styles.icons}/>
        </a>
      </div>
    </div>
  );
};

export default Footer;