import React, {MutableRefObject, useEffect, useState} from 'react';
import styles from './SectionsMenu.module.scss';
import cn from 'classnames';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {Icons} from "../Icons/Icons";

interface SectionsMenuProps {
  hrefs: MutableRefObject<HTMLElement | null>[];
}

export interface ActiveSection {
  id: number | null;
  className?: keyof Styles;
  title?: string;
}

export interface Styles {
  aside: string;
  navList: string;
  navItem: string;
  focusedNavItem: string;
  activeTitle: string;
  welcome: string;
  intellzyIcon: any;
}



export interface NavElement {
  id: number;
  section: MutableRefObject<HTMLElement | null>;
  className: string | React.ReactNode;
  title: string | React.ReactNode;
}

const SectionsMenu: React.FC<SectionsMenuProps> = ({ hrefs }) => {
  const [activeSection, setActiveSection] = useState<NavElement | null>(null);
  const navElements: NavElement[] = Array.from({ length: hrefs.length }).map((_, index) => ({
    id: index,
    section: hrefs[index],
    className: getClassNameByIndex(index),
    title: getTitleByIndex(index),
  }));

  function getTitleByIndex(index: number): string | React.ReactNode {
    switch (index) {
      case 0:
        return <Icons name={"intellzy"} className={styles.intellzyIcon} />;
      case 1:
        return "About Us";
      case 2:
        return "Projects";
      case 3:
        return "Services";
      case 4:
        return "Courses";
      case 5:
        return "Team";
      case 6:
        return "Contact Us";
      default:
        return "";
    }
  }

  function getClassNameByIndex(index: number): string | React.ReactNode {
    switch (index) {
      case 0:
        return "welcome";
      case 1:
        return "aboutUs";
      case 2:
        return "projects";
      case 3:
        return "services";
      case 4:
        return "courses";
      case 5:
        return "team";
      case 6:
        return "contactUs";
      default:
        return "";
    }
  }


  useEffect(() => {
    navElements.forEach((section, index) => {
      if (section.section && section.section.current) {
        ScrollTrigger.create({
          trigger: section.section.current,
          start: 'top 50% ',
          end: 'bottom 50%',
          onToggle: ({isActive} ) => {
            if (isActive) {
              setActiveSection(section);
            }
          },
        });
      }
    });
  }, []);

  const scrollToSection = (sectionRef: React.RefObject<HTMLElement>) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getNavItemClass = (index: number, activeSection: NavElement | null): string => {
    const isFocused: boolean = index === activeSection?.id;
    const isWelcomeTitle: boolean = typeof activeSection?.title !== "string";

    return cn(
      styles.navItem,
      isFocused && styles.focusedNavItem,
      isFocused && ![1, 4].includes(activeSection?.id || 0) && styles.activeTitle,
      activeSection?.className && styles[activeSection.className as keyof Styles],
      isWelcomeTitle && styles.welcome
    );
  };


  return (
    <aside className={styles.aside}>
      <nav>
        <ul className={styles.navList}>
          {navElements.map((navElement: NavElement, index) => (
            <li
              key={navElement.id}
              className={getNavItemClass(index, activeSection)}
              onClick={() => {scrollToSection(navElement.section)}}
            >
              {navElement.title}
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};

export default SectionsMenu;
