import React from 'react';

import * as Icon from './Icon.vectors';
import cn from "classnames";
import styles from "./Icons.module.scss"
interface IconPropsTypes {
  name: keyof typeof Icon;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
}

export const Icons: React.FC<IconPropsTypes> = (props) => {
  const { className, onClick, name } = props;
  return (
    <span className={cn(className, styles.wrapper)} onClick={onClick}>
      {Icon[name]}
    </span>
  );
};