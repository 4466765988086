import React, {useRef} from 'react';
import Welcome from "./sections/Welcome";
import Projects from "./sections/Projects";
import Services from "./sections/Services";
import Courses from "./sections/Courses";
import Team from "./sections/Team";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import ContactUs from "./sections/ContactUs";
import AboutUs from "./sections/AboutUs";
import SectionsMenu from "./components/SectionsMenu";
import styles from "./App.module.scss"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

function App() {
  const welcomeSectionRef = useRef<HTMLElement | null>(null);
  const aboutUsSectionRef = useRef<HTMLElement | null>(null);
  const projectsSectionRef = useRef<HTMLElement | null>(null);
  const servicesSectionRef = useRef<HTMLElement | null>(null);
  const coursesSectionRef = useRef<HTMLElement | null>(null);
  const teamSectionRef = useRef<HTMLElement | null>(null);
  const contactUsSectionRef = useRef<HTMLElement | null>(null);
  const container = useRef<HTMLDivElement | null>(null);

  const hrefs = [
    welcomeSectionRef,
    aboutUsSectionRef,
    projectsSectionRef,
    servicesSectionRef,
    coursesSectionRef,
    teamSectionRef,
    contactUsSectionRef
  ]


  return (
    <main ref={container} className={styles.main}>
      <SectionsMenu hrefs={hrefs}/>
      <Welcome ref={welcomeSectionRef} />
      <AboutUs ref={aboutUsSectionRef} />
      <Projects ref={projectsSectionRef} />
      <Services ref={servicesSectionRef} />
      <Courses ref={coursesSectionRef} />
      <Team ref={teamSectionRef} />
      <ContactUs ref={contactUsSectionRef} />
    </main>
  );
}

export default App;
