import React, {forwardRef} from "react";
import styles from './Courses.module.scss'

const Courses:React.ForwardRefRenderFunction<HTMLElement> = (props,ref) => {
  return (
    <section className={styles.wrapper} ref={ref}>
      <span className={styles.sectionName}>Courses</span>
      <div className={styles.content}>
        <div className={styles.textWrapper}>
          <h4>Coding courses</h4>
          <div className={styles.mainText}>
            <p>
              Lay a solid foundation with our <strong>HTML</strong> and <strong>CSS</strong> courses. From structuring content to styling pages, you'll gain the skills needed to create visually appealing and user-friendly interfaces.
              Dive into the world of front-end development with <strong>React</strong> and <strong>Redux</strong>. Learn to build interactive, responsive user interfaces and manage state effectively, following industry best practices.
              Collaborate seamlessly with <strong>Git</strong>. Our courses teach you version control essentials, enabling you to manage project changes efficiently and work harmoniously with team members.
              Elevate your project management capabilities using <strong>JIRA</strong>.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default forwardRef(Courses)
